.wf-theme-border .avue--detail .avue-form__group,
.wf-theme-border .avue--detail .avue-form__row {
  border: 1px solid #333 !important;
}
.wf-theme-border .avue--detail .el-form-item > .el-form-item__content {
  border-left: 2px solid #333 !important;
}
.wf-theme-border .avue--detail .el-table__row .el-form-item__content {
  border-left: none !important;
}
.wf-theme-border .avue--detail .avue-form__row {
  margin-bottom: 0px !important;
}
.wf-theme-border .avue--detail .el-form-item {
  margin-bottom: 0px !important;
}
.wf-theme-border .avue--detail .el-form-item__label {
  text-align: center !important;
}
.wf-theme-border .avue--detail .el-input.is-disabled .el-input__inner,
.wf-theme-border .avue--detail .el-range-editor.is-disabled,
.wf-theme-border .avue--detail .el-textarea.is-disabled .el-textarea__inner {
  border: 1px solid transparent;
  background: #fff;
  background-color: #fff;
}
.wf-theme-border .avue--detail .el-input-number.is-disabled .el-input-number__decrease,
.wf-theme-border .avue--detail .el-input-number.is-disabled .el-input-number__increase {
  display: none;
}