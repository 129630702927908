.b1,
.b2 {
    width: 50vw !important;
    height: 50vw !important;
    position: absolute;
    bottom: 0%;
    left: 50%;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
    z-index: 0;
}

.b2 {
    background-size: 53% 45%;
    background-repeat: no-repeat;
    background-position: center 100%;
}

.b2 .rainBox {
    position: absolute;
    bottom: 0;
    width: 50%;
    height: 45%;
}

.b2 .rainBox canvas {
    position: absolute;
    bottom: 0;
}

.yy {
    position: absolute;
    bottom: 0;
    width: 70%;
    height: 10vh;
    border-radius: 50% 50% 0 0;
    background-color: rgba(72, 173, 255, 0.26);
    -webkit-box-shadow: 0 0 20vh rgba(72, 173, 255, 0.856);
            box-shadow: 0 0 20vh rgba(72, 173, 255, 0.856);
    -webkit-transform: translateY(130%) scale(1.6);
            transform: translateY(130%) scale(1.6);
}

.xg {
    position: absolute;
    -webkit-transform: rotateX(75deg);
            transform: rotateX(75deg);
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}

.xg0 {
    width: 75%;
    height: 62%;
    bottom: -26%;
    background: url(../../static/img/0.9d230ad2.png) no-repeat center;
    background-size: 100%;
    -webkit-animation: rotate3 20s linear infinite;
            animation: rotate3 20s linear infinite;
    opacity: 0.7;
}

.xg1 {
    width: 30%;
    height: 30%;
    bottom: -10%;
    background: url(../../static/img/1.66d3eb71.png) no-repeat center;
    background-size: 100%;
    -webkit-animation: rotate3 20s linear infinite;
            animation: rotate3 20s linear infinite;
}

.xg2 {
    width: 47%;
    height: 47%;
    bottom: -19%;
    background: url(../../static/img/2.eb3c19c5.png) no-repeat center;
    background-size: 100%;
    animation: rotate3 20s linear infinite reverse;
}

.xg3 {
    width: 70%;
    height: 70%;
    bottom: -31%;
    background: url(../../static/img/4.4ce60b88.png) no-repeat center;
    background-size: 100%;
    -webkit-animation: rotate3 20s linear infinite;
            animation: rotate3 20s linear infinite;
}

.xg4 {
    width: 55%;
    height: 55%;
    bottom: -23%;
    background: url(../../static/img/3.81cd25f6.png) no-repeat center;
    background-size: 100%;
    animation: rotate3 20s linear infinite reverse;
}

.xg5 {
    width: 85%;
    height: 85%;
    bottom: -39%;
    background: url(../../static/img/5.d91848ad.png) no-repeat center;
    background-size: 100%;
    animation: rotate3 20s linear infinite reverse;
    opacity: 0.7;
}

.xg6 {
    width: 100%;
    height: 100%;
    bottom: -47%;
    background: url(../../static/img/6.a38f45cb.png) no-repeat center;
    background-size: 100%;
    -webkit-animation: rotate3 20s linear infinite;
            animation: rotate3 20s linear infinite;
    opacity: 0.5;
}

.xg61 {
    width: 100%;
    height: 100%;
    bottom: -47%;
    background: url(../../static/img/61.1bb51f59.png) no-repeat center;
    background-size: 100%;
    -webkit-animation: rotate3 20s linear infinite;
            animation: rotate3 20s linear infinite;
}

.xg7 {
    width: 100%;
    height: 100%;
    bottom: -47%;
    background: url(../../static/img/7.54f5b8de.png) no-repeat center;
    background-size: 100%;
    -webkit-animation: rotate3 20s linear infinite;
            animation: rotate3 20s linear infinite;
}

.xg2c {
    width: 27%;
    height: 27%;
    bottom: -6%;
    background: url(../../static/img/2c.9d276d5c.png) no-repeat center;
    background-size: 100%;
    -webkit-animation: rotate3 20s linear infinite;
            animation: rotate3 20s linear infinite;
}

.xg2c1 {
    width: 79%;
    height: 79%;
    bottom: -32%;
    background: url(../../static/img/2c1.ab969b2c.png) no-repeat center;
    background-size: 100%;
    -webkit-animation: rotate3 20s linear infinite;
            animation: rotate3 20s linear infinite;
}

@-webkit-keyframes rotate3 {
    0% {
        -webkit-transform: translateX(-50%) rotateX(80deg) rotateZ(0);
                transform: translateX(-50%) rotateX(80deg) rotateZ(0);
    }

    100% {
        -webkit-transform: translateX(-50%) rotateX(80deg) rotateZ(360deg);
                transform: translateX(-50%) rotateX(80deg) rotateZ(360deg);
    }
}

@keyframes rotate3 {
    0% {
        -webkit-transform: translateX(-50%) rotateX(80deg) rotateZ(0);
                transform: translateX(-50%) rotateX(80deg) rotateZ(0);
    }

    100% {
        -webkit-transform: translateX(-50%) rotateX(80deg) rotateZ(360deg);
                transform: translateX(-50%) rotateX(80deg) rotateZ(360deg);
    }
}

@-webkit-keyframes shan {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

@keyframes shan {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}